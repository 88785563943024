import { PageProps } from "gatsby";
import React from "react";

import { Index } from "../components/pages/Index";

import { snProps } from "../js/utils";

const IndexPage = (props: PageProps) => <Index {...{ ...snProps, ...props }} />;

export default IndexPage;
